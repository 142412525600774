<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="商户名称">
          <el-select v-model="formInline.shopId" filterable clearable placeholder="请选择商户">
            <el-option v-for="(item,index) in shopList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户姓名">
          <el-input v-model="formInline.name" clearable placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="用户电话">
          <el-input v-model="formInline.phone" clearable placeholder="请输入收货人姓名"></el-input>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            v-model="dateTimes"
            type="daterange"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateTimesChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="exportXcle">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod">
      </el-table-column>
      <el-table-column
        label="商户名称"
        prop="shopname">
      </el-table-column>
      <el-table-column
        label="用户姓名"
        prop="name">
      </el-table-column>
      <el-table-column
        label="用户电话"
        prop="phone">
      </el-table-column>
      <el-table-column
        label="支付积分数"
        prop="points">
      </el-table-column>
      <el-table-column
        label="下单时间"
        prop="create_time">
      </el-table-column>
    </el-table>

    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRow">
      </el-pagination>
    </div>

    <el-dialog :visible.sync="imgDialogVisible" title="图片预览" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>

  </div>
</template>

<script>
import { scanpayList, exportScanpayRecord } from '@/api/orderManage'
import { sureMessageBox }　from '@/utils/common'
import { getShopsPage } from '@/api/merchantManage'
export default {
  name:"",
  components:{},
  props:{},
  data(){
    return {
      formInline: {
        name: '',
        shopId: '',
        phone: '',
        date: '',
        startTime: '',
        endTime: '',
      },
      dateTimes: [],
      shopList: [],
      tableData: [],
      tableDataExport: [],
      currentPage: 1,
      totalRow: 0,
      pageSize: 10,
      dialogImageUrl: '',
      imgDialogVisible: false
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.getShopList();
    this.getScanpayList();
  },
  computed:{},
  methods:{
    dateTimesChange(val) {
      if(val) {
        this.formInline.startTime = val[0];
        this.formInline.endTime = val[1];
      } else {
        this.formInline.startTime = null;
        this.formInline.endTime = null;
      }
    },
    //获取订单列表
    getScanpayList(){
      scanpayList({
        name: this.formInline.name,
        shopId: this.formInline.shopId,
        phone: this.formInline.phone,
        // date: this.formInline.date,
        startDate: this.formInline.startTime,
        endDate: this.formInline.endTime,
        pageSize: this.pageSize,
        pageNum: this.currentPage
      }).then(res=>{
        // console.log(res)
        if(res.result == 200){
          this.tableData = res.detail.list;
          this.totalRow = res.detail.totalRow;
        }else{
          this.$message.warning(res.description)
        }
      })
    },
    exportXcle() {
      exportScanpayRecord({
        name: this.formInline.name,
        phone: this.formInline.phone,
        shopId: this.formInline.shopId,
        // date: this.formInline.date,
        startDate: this.formInline.startTime,
        endDate: this.formInline.endTime,
      }).then(res=>{
        if(res.result == 201){ //.result == 200
          this.$message.warning(res.description)
        }else{
          const data = res;
          const a = document.createElement('a');
          const blob = new Blob([data],{type:"application/octet-stream"});
          const blobUrl = window.URL.createObjectURL(blob);
          a.style.display = 'none';
          a.download = '扫码支付记录.xls'; //文件名
          a.href = blobUrl;
          a.click();
        }
      })
    },
    //获取商户列表
    getShopList() {
      getShopsPage({
        name: '',
        phone: '',
        pageSize: 999999,
        pageNum: 1
      }).then(res=>{
        // console.log(res)
        if(res.result == 200){
          this.shopList = res.detail.list;
        }else{
          this.$message.warning(res.description)
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getScanpayList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getScanpayList();
    },
    //自定义序号
    indexMethod(index) {
      return (this.currentPage - 1)*this.pageSize + (index+1);
    },
    onSearch() {
      console.log('submit!');
      this.currentPage = 1;
      this.getScanpayList();
    }
    
  }
}
</script>

<style lang="scss">
  .searchbox {
    margin: 20px 10px;
  }
  .order-table-expand {
    font-size: 0;
  }
  .order-table-expand label {
    width: 110px;
    color: #99a9bf;
  }
  .order-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  
  .pagebox{
    margin: 20px 0;
    text-align: right;
  }

  .goodimg{
    cursor: pointer;
    img{
      height: 100px;
      width: auto;
    }
  }
</style>